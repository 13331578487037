exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awkward-js": () => import("./../../../src/pages/Awkward.js" /* webpackChunkName: "component---src-pages-awkward-js" */),
  "component---src-pages-chemistry-js": () => import("./../../../src/pages/Chemistry.js" /* webpackChunkName: "component---src-pages-chemistry-js" */),
  "component---src-pages-coffee-js": () => import("./../../../src/pages/Coffee.js" /* webpackChunkName: "component---src-pages-coffee-js" */),
  "component---src-pages-decore-js": () => import("./../../../src/pages/Decore.js" /* webpackChunkName: "component---src-pages-decore-js" */),
  "component---src-pages-forecast-js": () => import("./../../../src/pages/Forecast.js" /* webpackChunkName: "component---src-pages-forecast-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

